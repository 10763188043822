import Layout from "../components/layout";
import Seo from "../components/seo";
import React from "react";


const GoPkgAksaraService = () => {
    const post = { "id": "go-akasara-service", "title": "Golang Aksara Service", "content": "",
        "pkg_url":"pkg.aksaratech.cloud/go-akasara-service",
        "repo_url": "https://git.aksaratech.com",
        "pkg_repo_url": "aksaratech/services/packages/aksara-service",
        "prod_branch": "main"
    }

    const buildMeta = [{
        name: "go-import",
        content: `${post.pkg_url} git ${post.repo_url}/${post.pkg_repo_url}`
    },
        {
            name: "go-source",
            content:`${post.pkg_url} ${post.repo_url}/${post.pkg_repo_url} ${post.repo_url}/${post.pkg_repo_url}/tree/${post.prod_branch}{/dir} ${post.repo_url}/${post.pkg_repo_url}/blob/${post.prod_branch}{/dir}/{file}#L{line}`
        }
    ]
    return (
        <Layout>
            <Seo title="Aksara Package " meta={buildMeta} />
            <h1>{post.title}</h1>
            <p>How to install</p>
            <code>
                touch ~/.netrc <br/>
                echo machine [url] login [username] password [token] > ~/.netrc<br/>
                go env -w GONOSUMDB='{post.repo_url},[previous value]'<br/>
                go get -u {post.repo_url}/{post.pkg_repo_url}
            </code>
        </Layout>
    )
}

export default GoPkgAksaraService
